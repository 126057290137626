const getStoredCookies = document.cookie;

if (!getStoredCookies.includes("cookie-notification-banner=1")) {
	const cookieNotificationContainer = document.createElement("div");
	cookieNotificationContainer.setAttribute("class", "cookie-notification");

	const content = document.createElement("p");
	content.innerHTML = `Mit der Nutzung unserer Website erklärst Du dich mit unserer <a href="privacy.html" class="text-link">Datenschutzerklärung</a> einverstanden.`;
	cookieNotificationContainer.appendChild(content);

	const closeButton = document.createElement("button");
	closeButton.innerHTML = `<svg width="100%" height="100%" viewBox="0 0 14 14"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g transform="translate(1.000000, 1.000000)" stroke="hsl(218, 41%, 25%)" stroke-width="2"><polyline points="0 0 6 6 12 0"></polyline><polyline transform="translate(6.000000, 9.000000) scale(1, -1) translate(-6.000000, -9.000000) " points="0 6 6 12 12 6"></polyline></g></g></svg>`;
	cookieNotificationContainer.appendChild(closeButton);

	document.body.appendChild(cookieNotificationContainer);

	closeButton.addEventListener("click", () => {
		document.cookie = "cookie-notification-banner=1";
		cookieNotificationContainer.style.transform = "translateY(150%)";
		setTimeout(() => {
			cookieNotificationContainer.remove();
		}, 300);
	});
}
